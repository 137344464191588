import type { FC } from 'react';
import { Icon, SvgIcon, Theme } from '@mui/material';
import { experimentalStyled } from '@mui/material/styles';
import { height, SxProps, width } from '@mui/system';
import { ReactComponent as logo } from '../graphics/logo.svg';

interface LogoProps {
  sx?: SxProps<Theme>;
}

const Logo: FC<LogoProps> = (props) => (
  <Icon
    component={logo}
    style={{
      filter: 'invert(100%) sepia(0) saturate(25%) brightness(108%) contrast(108%)',
    }}
    sx={{
      height: 52,
      width: 52,
      version: 1.1,
    }}
    {...props}
  />
);

export default Logo;
