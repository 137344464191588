import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import Cookie from 'src/lib/cookies';

import CustomerDataApi from 'src/apis/CustomerDataApi';
import {
  CustomerStatusResponse,
  CustomerDataResponse,
  UpsertCustomerStatusRequest,
  UpsertCustomerDataRequest,
  CustomerStatusesResponse,
} from 'src/apis/types/CustomerDataAPITypes';

import type { AppThunk } from '../store';
import objFromArray from '../utils/objFromArray';

interface CustomerDataState {
  customerData: {
    self: CustomerDataResponse;
    byId: Record<string, CustomerDataResponse>;
    allIds: string[];
  };
  status: {
    byId: Record<string, CustomerStatusResponse>;
    allIds: string[];
    allStatuses: CustomerStatusResponse[];
  };
}

const initialState: CustomerDataState = {
  customerData: {
    self: null,
    byId: {},
    allIds: [],
  },
  status: {
    byId: {},
    allIds: [],
    allStatuses: [],
  },
};

const slice = createSlice({
  name: 'customerData',
  initialState,
  reducers: {
    getStatus(
      state: CustomerDataState,
      action: PayloadAction<{ status: CustomerStatusesResponse }>,
    ): void {
      const { status } = action.payload;

      state.status.byId = objFromArray(status.statuses);
      state.status.allIds = Object.keys(state.status.byId);
      state.status.allStatuses = Object.values(state.status.byId);

      const arr = [];
      arr.push(status);
    },
    getCustomerDataForSelf(
      state: CustomerDataState,
      action: PayloadAction<{ data: CustomerDataResponse }>,
    ): void {
      const { data } = action.payload;
      state.customerData.self = data;
    },
    getCustomerData(
      state: CustomerDataState,
      action: PayloadAction<{ data: CustomerDataResponse }>,
    ): void {
      const { data } = action.payload;

      if (!state.customerData.allIds[data.customerUUID]) {
        state.customerData.allIds.push(data.customerUUID);
      }
      if (!state.customerData.byId[data.customerUUID]) {
        state.customerData.self = data;
      }
    },
    updateCustomerData(
      state: CustomerDataState,
      action: PayloadAction<{ request: CustomerDataResponse }>,
    ): void {
      const { request } = action.payload;
      Object.assign(state.customerData.self, request);
    },
    updateCustomerStatus(
      state: CustomerDataState,
      action: PayloadAction<{ request: CustomerStatusResponse }>,
    ): void {
      const { request } = action.payload;
      Object.assign(state.status.byId[request.customerUUID], request);
    },
  },
});

export const { reducer } = slice;

export const getStatuses =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    const uuid = Cookie.getCookie(Cookie.Keys.UUID);
    const response = await CustomerDataApi.getCustomerStatusesForCustomer(uuid);

    dispatch(slice.actions.getStatus({ status: response }));
  };

export const getCustomerDataForSelf =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    const uuid = Cookie.getCookie(Cookie.Keys.UUID);
    const response = await CustomerDataApi.getCustomerDataByUUID(uuid);
    dispatch(slice.actions.getCustomerDataForSelf({ data: response }));
  };

export const getCustomerData =
  (uuid: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const response = await CustomerDataApi.getCustomerDataByUUID(uuid);

    dispatch(slice.actions.getCustomerData({ data: response }));
  };

export const updateCustomerData =
  (request: UpsertCustomerDataRequest): AppThunk =>
  async (dispatch): Promise<void> => {
    const uuid = Cookie.getCookie(Cookie.Keys.UUID);
    const response = await CustomerDataApi.upsertCustomerData(uuid, request);

    dispatch(slice.actions.updateCustomerData({ request: response }));
  };

export const updateCustomerStatus =
  (request: UpsertCustomerStatusRequest): AppThunk =>
  async (dispatch): Promise<void> => {
    const response = await CustomerDataApi.upsertCustomerStatus(request);

    dispatch(slice.actions.updateCustomerStatus({ request: response }));
  };

export default slice;
