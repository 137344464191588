import { useEffect, type FC } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Box, Card, CardContent, Container, useTheme } from '@mui/material';
import StytchLoginComponent from 'src/components/authentication/login/StytchLogin';
import Logo from '../../components/Logo';

const StytchLoginPage: FC = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <Container
        maxWidth="sm"
        sx={{ py: '80px' }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mb: 6,
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 150,
                width: 150,
              }}
            />
          </RouterLink>
        </Box>
        <Card>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              p: 4,
            }}
          >
            <Box
              sx={{
                alignItems: 'center',
                display: 'contents',
                justifyContent: 'center',
                mb: 3,
              }}
            >
              <StytchLoginComponent />
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default StytchLoginPage;
