import { AuthProviderSignupRequest } from './types/LoginAPITypes';

const BaseUrl = 'https://u6jvaam8x8.execute-api.us-east-2.amazonaws.com/Prod/login_service/v1';

// login_service/forgot/{id}/username GET
async function forgotUsername(email: string) {
  email = encodeURI(email);
  const resp = await fetch(`${BaseUrl}/forgot/${email}/username`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  }).then((response) => response.json());
  return resp;
}

// login_service/forgot/{id}/password POST
async function forgotPassword(uuid: string, uri: string) {
  const data = {
    url: uri,
  };
  uuid = encodeURI(uuid);
  const resp = await fetch(`${BaseUrl}/forgot/${uuid}/password`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify(data),
  }).then((response) => response.json());
  return resp;
}

// login_service/login/{id}/{password} GET
async function login(username: string, password: string) {
  password = encodeURI(password);
  const resp = await fetch(`${BaseUrl}/login/${username}/${password}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  });
}

// login_service/password/change POST
async function changePassword(user_name: string, old_password: string, new_password: string) {
  const data = {
    username: user_name,
    old_password: old_password,
    new_password: new_password,
  };
  const resp = await fetch(`${BaseUrl}/password/change`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify(data),
  }).then((response) => response.json());
  return resp;
}

// login_service/password/forgot POST
async function resetPassword(user_name: string, user_token: string, new_password: string) {
  const data = {
    username: user_name,
    token: user_token,
    password: new_password,
  };
  const resp = await fetch(`${BaseUrl}/password/reset`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify(data),
  }).then((response) => response.json());
  return resp;
}

// login_service/register/ POST
async function createCustomer(
  user_name: string,
  user_email: string,
  user_phone_number: string,
  user_password: string,
) {
  const body = {
    username: user_name,
    email: user_email,
    phone_number: user_phone_number,
    password: user_password,
  };
  const resp = await fetch(`${BaseUrl}/register`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify(body),
  }).then((response) => response.json());
  return resp.uuid;
}

// login_service/register/{id} GET
async function checkIdentifier(uuid: string) {
  uuid = encodeURI(uuid);
  const resp = await fetch(`${BaseUrl}/register/${uuid}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  });
  return resp.ok;
}

// login_service/two_fac/{id}/email GET
async function sendTwoFactorEmail(uuid: string) {
  uuid = encodeURI(uuid);
  const resp = await fetch(`${BaseUrl}/two_fac/${uuid}/email`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  });
}

// login_service/two_fac/{id}/sms GET
async function sendTwoFactorSMS(uuid: string) {
  uuid = encodeURI(uuid);
  const resp = await fetch(`${BaseUrl}/two_fac/${uuid}/sms`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  });
}

// login_service/two_fac/{id}/{code} GET
async function verifyTwoFactor(uuid: string, code: string) {
  uuid = encodeURI(uuid);
  code = encodeURI(code);
  const resp = await fetch(`${BaseUrl}/two_fac/${uuid}/${code}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  }).then((response) => response.json());
  return resp;
}

// login_service/auth_service/ POST
async function authProviderSignup(request: AuthProviderSignupRequest) {
  const resp = await fetch(`${BaseUrl}/auth_provider`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(request),
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  }).then((response) => response.json());
  return resp;
}

// login_service/auth_service/{id} GET
async function getUUIDFromAuthProvider(id: string) {
  const resp = await fetch(`${BaseUrl}/auth_provider/${id}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  }).then((response) => response.json());
  return resp;
}

const LoginAPI = {
  forgotUsername,
  forgotPassword,
  login,
  changePassword,
  resetPassword,
  createCustomer,
  checkIdentifier,
  sendTwoFactorEmail,
  sendTwoFactorSMS,
  verifyTwoFactor,
  authProviderSignup,
  getUUIDFromAuthProvider,
};

export default LoginAPI;
