import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import DocsLayout from './components/docs/DocsLayout';
import { StripePayment } from './components/checkout/StripePayment';
import StytchLoginComponent from './pages/authentication/StytchLogin';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Authentication pages
const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const StytchLogin = Loadable(lazy(() => import('./pages/authentication/StytchLogin')));
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));
const Register = Loadable(lazy(() => import('./pages/authentication/Register')));
const UsernameRecovery = Loadable(lazy(() => import('./pages/authentication/UsernameRecovery')));
const VerifyCode = Loadable(lazy(() => import('./pages/authentication/VerifyCode')));

// Dashboard pages
const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));
const Analytics = Loadable(lazy(() => import('./pages/dashboard/Analytics')));
const Calendar = Loadable(lazy(() => import('./pages/dashboard/Calendar')));
const Chat = Loadable(lazy(() => import('./pages/dashboard/Chat')));
const Finance = Loadable(lazy(() => import('./pages/dashboard/Finance')));
const OrgChart = Loadable(lazy(() => import('./pages/dashboard/OrgChart')));
const Notifications = Loadable(lazy(() => import('./pages/dashboard/Notifications')));
const Notepad = Loadable(lazy(() => import('./pages/dashboard/Notepad')));
const Contacts = Loadable(lazy(() => import('./pages/dashboard/Contacts')));

const Kanban = Loadable(lazy(() => import('./pages/dashboard/Kanban')));
const KanbanBoard = Loadable(lazy(() => import('./pages/dashboard/KanbanBoardPage')));
const Overview = Loadable(lazy(() => import('./pages/dashboard/Overview')));

// Stripe pages
const StripePrices = Loadable(lazy(() => import('./pages/stripe/StripePrices')));

// Docs pages
const Docs = Loadable(lazy(() => import('./pages/Docs')));

// Error pages
const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

const routes = [
  {
    path: 'authentication',
    children: [
      // {
      //   path: 'login-guest-guard',
      //   element: (
      //     // <GuestGuard>
      //     <Login />
      //     // </GuestGuard>
      //   ),
      // },
      {
        path: 'login',
        element: <StytchLogin />,
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />,
      },
      {
        path: 'password-reset',
        element: <PasswordReset />,
      },
      // {
      //   path: 'register-guest-guard',
      //   element: (
      //     <GuestGuard>
      //       <Register />
      //     </GuestGuard>
      //   ),
      // },
      {
        path: 'register',
        element: <Register />,
      },
      // {
      //   path: 'username-recovery',
      //   element: <UsernameRecovery />,
      // },
      {
        path: 'verify-code',
        element: <VerifyCode />,
      },
    ],
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      /*
      {
        path: '',
        element: <Overview />
      },
      */
      {
        path: 'account',
        element: <Account />,
      },
      /*
      {
        path: 'org-chart',
        element: <OrgChart />
      },
      */
      {
        path: 'notifications',
        element: <Notifications />,
      },
      /*
       {
         path: 'contacts',
         element: <Contacts />
       },
       */
      /*
       {
         path: 'analytics',
         element: <Analytics />
       },
       */
      {
        path: 'calendar',
        element: <Calendar />,
      },
      {
        path: 'chat',
        children: [
          {
            path: '',
            element: <Chat />,
          },
          /*
          {
            path: 'new',
            element: <Chat />
          },
          */
          {
            path: ':threadKey',
            element: <Chat />,
          },
        ],
      },
      {
        path: 'kanban',
        children: [
          {
            path: '',
            element: <Kanban />,
          },
          {
            path: 'board/:boardId',
            element: <KanbanBoard boardId=":boardId" />,
          },
          {
            path: 'board/',
            element: (
              <Navigate
                to="/dashboard/kanbans"
                replace
              />
            ),
          },
          {
            path: 'board/:boardId/:cardId',
            element: <KanbanBoard boardId=":boardId" />,
          },
        ],
      },
      /*
      {
        path: 'mail',
        children: [
          {
            path: '',
            element: (
              <Navigate
                to="/dashboard/mail/all"
                replace
              />
            )
          },
          {
            path: 'label/:customLabel',
            element: <Mail />
          },
          {
            path: 'label/:customLabel/:emailId',
            element: <Mail />
          },
          {
            path: ':systemLabel',
            element: <Mail />
          },
          {
            path: ':systemLabel/:emailId',
            element: <Mail />
          }
        ]
      },
      */
      {
        path: 'notepad',
        element: <Notepad />,
      },
      /*
       {
         path: 'finance',
         element: <Finance />
       }
       */
    ],
  },
  {
    path: 'docs',
    element: <DocsLayout />,
    children: [
      {
        path: '',
        element: (
          <Navigate
            to="/docs/overview/welcome"
            replace
          />
        ),
      },
      {
        path: '*',
        element: <Docs />,
      },
    ],
  },
  {
    path: 'checkout',
    element: <StripePrices />,
  },
  {
    path: '*',
    children: [
      {
        path: '',
        element: <StytchLoginComponent />,
      },
      {
        path: '401',
        element: <AuthorizationRequired />,
      },
      {
        path: '404',
        element: <NotFound />,
      },
      {
        path: '500',
        element: <ServerError />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
