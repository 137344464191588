import {
  CustomerDataResponse,
  CustomerStatusResponse,
  CustomerStatusesResponse,
  UpsertCustomerStatusRequest,
  UpsertCustomerDataRequest,
} from './types/CustomerDataAPITypes';

const BaseUrl =
  'https://5ll18vznrk.execute-api.us-east-2.amazonaws.com/Prod/customer_data_service/v1';

async function getCustomerData(id: string): Promise<CustomerDataResponse> {
  id = encodeURI(id);
  const resp = await fetch(`${BaseUrl}/customer_data/${id}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  }).then((response) => response.json());
  return resp;
}

async function getCustomerDataByUUID(uuid: string): Promise<CustomerDataResponse> {
  uuid = encodeURI(uuid);
  const resp = await fetch(`${BaseUrl}/customer/${uuid}/customer_data`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  }).then((response) => response.json());
  return resp;
}

async function getCustomerStatusesForCustomer(uuid: string): Promise<CustomerStatusesResponse> {
  uuid = encodeURI(uuid);
  const resp = await fetch(`${BaseUrl}/customer/${uuid}status/`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  }).then((response) => response.json());
  return resp;
}

async function getCustomerStatus(id: string): Promise<CustomerStatusResponse> {
  id = encodeURI(id);
  const resp = await fetch(`${BaseUrl}/status/${id}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  }).then((response) => response.json());
  return resp;
}

async function upsertCustomerData(
  uuid: string,
  request: UpsertCustomerDataRequest,
): Promise<CustomerDataResponse> {
  uuid = encodeURI(uuid);
  const resp = await fetch(`${BaseUrl}/customer_data/${uuid}`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(request),
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  }).then((response) => response.json());
  return resp;
}

async function upsertCustomerStatus(
  request: UpsertCustomerStatusRequest,
): Promise<CustomerStatusResponse> {
  const resp = await fetch(`${BaseUrl}/status`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify(request),
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  }).then((response) => response.json());
  return resp;
}

const CustomerDataAPI = {
  getCustomerData,
  getCustomerStatus,
  getCustomerStatusesForCustomer,
  getCustomerDataByUUID,
  upsertCustomerData,
  upsertCustomerStatus,
};

export default CustomerDataAPI;
