import Cookie from 'src/lib/cookies';
import { Notification, Notifications } from './types/NotificationAPITypes';

const BaseUrl =
  'https://uzqgahqwb8.execute-api.us-east-2.amazonaws.com/Prod/notification_service/v1';

async function getNotifications(uuid: string): Promise<Notifications> {
  uuid = encodeURI(uuid);
  const resp = await fetch(`${BaseUrl}/customer/${uuid}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  }).then((response) => response.json());
  return resp;
}

async function getNotification(id: string): Promise<Notifications> {
  id = encodeURI(id);
  const resp = await fetch(`${BaseUrl}/notification/${id}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  }).then((response) => response.json());
  return resp;
}

async function updateNotification(notif: Notification): Promise<Notifications> {
  const id = encodeURI(notif.notificationId);
  const uuid = Cookie.getCookie(Cookie.Keys.UUID);
  const data = {
    notificationId: notif.notificationId,
    customerUUID: uuid,
    service: notif.service,
    type: notif.type,
    seen: notif.seen,
  };
  notif = { ...notif, ...data };
  const resp = await fetch(`${BaseUrl}/notification/${id}`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify(data),
  }).then((response) => response.json());
  return resp;
}

const NotificationAPI = {
  getNotifications,
  getNotification,
  updateNotification,
};

export default NotificationAPI;
