import { useEffect, useState, type FC } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { StytchLogin, useStytch } from '@stytch/react';
import { Products, OneTapPositions, OAuthProviders, OAuthOptions } from '@stytch/vanilla-js';
import useAuth from 'src/hooks/useAuth';
import Logo from 'src/components/Logo';
import { getDomainFromWindow } from 'src/lib/urlUtils';

// pulled from the stytch dasboard
const loginTemplateId = 'login_example';
const signupTemplateId = 'signup_basic';
const resetPasswordTemplateId = 'reset_password_basic';

const OAUTH_TOKEN = 'oauth';
const MAGIC_LINKS_TOKEN = 'magic_links';
const LOGIN = 'login';
const RESET_PASSWORD = 'reset_password';

const StytchLoginComponent: FC = () => {
  const stytch = useStytch();
  const theme = useTheme();
  const navigate = useNavigate();
  const { authProviderSignin } = useAuth();
  const auth = useAuth();

  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!auth.isAuthenticated) {
    if (
      location.pathname === undefined ||
      location.pathname === null ||
      (location.pathname !== requestedLocation && location.pathname !== '/')
    ) {
      setRequestedLocation(location.pathname);
      console.log('setRequestedLocation', location.pathname);
    }
  }

  useEffect(() => {
    if (auth.isAuthenticated) {
      // ensure we navigate to the requested location if we have one
      if (requestedLocation !== null) {
        navigate(requestedLocation);
        return;
      }
      navigate('/dashboard/chat/sid');
    }
  });

  useEffect(() => {
    // if we don't have a location, we can't do anything
    if (!location) {
      return;
    }
    // get the params from the location
    const params = location.search
      .slice(1)
      .split('&')
      .map((kv) => kv.split('='));
    // if we don't have at least 2 params, we can't do anything
    if (params.length < 2) {
      return;
    }

    // get the token type and the token
    const stytchTokenType = params[0][1];
    const token = params[1][1];

    // if we don't have a token, we can't do anything
    if (!token) {
      return;
    }

    if (stytchTokenType === RESET_PASSWORD) {
      navigate(`/authentication/password-reset${location.search}`);
    }

    if (stytchTokenType === OAUTH_TOKEN) {
      stytch.oauth
        .authenticate(token, {
          session_duration_minutes: 60 * 1,
        })
        .then((resp) => {
          if (resp.status_code === 200) {
            auth.authProviderSignin(
              resp.user.emails[0].email,
              resp.user_id,
              resp.session_token,
              resp.provider_type,
              resp.session.expires_at,
            );
            navigate('/dashboard/chat/sid');
            return;
          }
          if (resp.status_code === 401) {
            navigate('/');
          }
        });
    }

    if (stytchTokenType === MAGIC_LINKS_TOKEN || stytchTokenType === LOGIN) {
      stytch.magicLinks
        .authenticate(token, {
          session_duration_minutes: 60 * 24,
        })
        .then((resp) => {
          if (resp.status_code === 200) {
            auth.authProviderSignin(
              resp.user.emails[0].email,
              resp.user_id,
              resp.session_token,
              'token',
              resp.session.expires_at,
            );
            navigate('/dashboard/chat/sid');
            return;
          }
          if (resp.status_code === 401) {
            navigate('/');
          }
        });
    }
  }, [location, stytch]);

  const loginRedirectURL = '/dashboard/chat/sid';
  const signupRedirectURL = '/dashboard/chat/sid';
  const resetPasswordRedirectURL = '/authentication/password-reset';

  const stytchProps = {
    config: {
      products: [Products.passwords, Products.oauth],
      oauthOptions: {
        providers: [
          { type: OAuthProviders.Google }, // one_tap: true, position: OneTapPositions.embedded },
          { type: OAuthProviders.Github },
          // { type: OAuthProviders.Apple} would love to get this one enabled
        ],
      },
      sessionOptions: {
        sessionDurationMinutes: 60,
      },
      passwordOptions: {
        loginRedirectURL: `${getDomainFromWindow()}`,
        loginExpirationMinutes: 10,
        resetPasswordRedirectURL: `${getDomainFromWindow()}`, // this is broken, now we have to ask ourselves why.....
        resetPasswordExpirationMinutes: 10,
      },
      enableShadowDOM: true,
    },
    styles: {
      container: {
        backgroundColor: 'primary.main', // theme.palette.background.paper,
        color: theme.palette.secondary.main,
        borderRadius: theme.shape.borderRadius.toString(),
        width: '100%',
      },
      button: {
        backgroundColor: theme.palette.secondary.main, // theme.palette.background.paper,
        color: theme.palette.secondary.main,
      },
      buttons: {
        primary: {
          backgroundColor: theme.palette.primary.main,
          textColor: theme.palette.text.primary,
          borderColor: '#19303D',
          borderRadius: '4px',
        },
        secondary: {
          backgroundColor: '#e8e8e8',
          textColor: '#19303D',
          borderColor: '#19303D',
          borderRadius: '4px',
        },
      },
      colors: {
        primary: theme.palette.text.primary,
        secondary: theme.palette.text.secondary,
      },
      fontFamily: theme.typography.fontFamily,
      logo: {
        // logoImageUrl: 'https://s3.us-east-2.amazonaws.com/cdn.my-sid/public/logo.svg'
      },
    },
    callbacks: {
      onEvent: async (message) => {
        console.log(JSON.stringify(message));
        const { data } = message;

        if (message.type === 'PASSWORD_AUTHENTICATE' || message.type === 'PASSWORD_CREATE') {
          await authProviderSignin(
            data.user.emails[0].email,
            data.user_id,
            data.session_token,
            'stytch',
            data.session.expires_at,
          );
        }
      },
      onSuccess: (message) => {
        console.log('onSuccess');
        console.log(message);
      },
      onError: (message) => {
        console.log('onError');
        console.log(message);
      },
    },
  };

  return (
    <StytchLogin
      config={stytchProps.config}
      callbacks={stytchProps.callbacks}
      styles={stytchProps.styles}
    />
  );
};

export default StytchLoginComponent;
