import {
  Attachment,
  Board,
  Card,
  Checklist,
  CheckListItem,
  Column,
  Comment,
} from './types/KanbanAPITypes';

const BaseUrl = 'https://hmaj8gwppd.execute-api.us-east-2.amazonaws.com/Prod/kanban_service/v1';

async function upsertAttachment(attachment: Attachment) {
  const body = attachment;
  const resp = await fetch(`${BaseUrl}/attachment`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify(body),
  }).then((response) => response.json());
  return resp;
}

async function deleteAttachment(id: string) {
  const resp = await fetch(`${BaseUrl}/attachment/${id}`, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  }).then((response) => response.json());
  return resp;
}

async function upsertBoard(board: Board): Promise<Board> {
  const body = board;
  const resp = await fetch(`${BaseUrl}/board`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify(body),
  }).then((response) => response.json());
  return resp;
}

async function getBoard(id: string): Promise<Board> {
  const resp = await fetch(`${BaseUrl}/board/${id}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  }).then((response) => response.json());
  return resp;
}

async function deleteBoard(id: string): Promise<boolean> {
  const resp = await fetch(`${BaseUrl}/board/${id}`, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  }).then((response) => response.json());
  return resp;
}

async function getCard(id: string): Promise<Card> {
  const resp = await fetch(`${BaseUrl}/card/${id}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  }).then((response) => response.json());
  return resp;
}

async function upsertCard(card: Card): Promise<Card> {
  const body = card;
  const resp = await fetch(`${BaseUrl}/card`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify(body),
  }).then((response) => response.json());
  return resp;
}

async function deleteCard(id: string): Promise<boolean> {
  const resp = await fetch(`${BaseUrl}/card/${id}`, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  }).then((response) => response.json());
  return resp;
}

async function upsertColumn(column: Column): Promise<Column> {
  const body = column;
  const resp = await fetch(`${BaseUrl}/column`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify(body),
  }).then((response) => response.json());
  return resp;
}

async function getColumn(id: string): Promise<Column> {
  const resp = await fetch(`${BaseUrl}/column/${id}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  }).then((response) => response.json());
  return resp;
}

async function deleteColumn(id: string): Promise<boolean> {
  const resp = await fetch(`${BaseUrl}/column/${id}`, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  }).then((response) => response.json());
  return resp;
}

async function upsertComment(comment: Comment): Promise<Comment> {
  const body = comment;
  const resp = await fetch(`${BaseUrl}/comment`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify(body),
  }).then((response) => response.json());
  return resp;
}

async function deleteComment(id: string): Promise<boolean> {
  const resp = await fetch(`${BaseUrl}/comment/${id}`, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  }).then((response) => response.json());
  return resp;
}

async function getAllBoardDataForCustomer(uuid: string): Promise<Board[]> {
  const resp = await fetch(`${BaseUrl}/customer/${uuid}/board`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  }).then((response) => response.json());
  return resp.boards;
}

async function getBoards(uuid: string): Promise<Board[]> {
  const resp = await fetch(`${BaseUrl}/customer/${uuid}/boards`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  }).then((response) => response.json());
  return resp.boards;
}

async function upsertChecklist(checklist: Checklist): Promise<Checklist> {
  const body = checklist;
  const resp = await fetch(`${BaseUrl}/checklist`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify(body),
  }).then((response) => response.json());
  return resp;
}

async function deleteChecklist(id: string): Promise<boolean> {
  const resp = await fetch(`${BaseUrl}/checklist/${id}`, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  }).then((response) => response.json());
  return resp;
}

async function upsertChecklistItem(checkItem: CheckListItem): Promise<CheckListItem> {
  const body = checkItem;
  const resp = await fetch(`${BaseUrl}/checklist_item`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify(body),
  }).then((response) => response.json());
  return resp;
}

async function deleteChecklistItem(id: string): Promise<boolean> {
  const resp = await fetch(`${BaseUrl}/checklist_item/${id}`, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  }).then((response) => response.json());
  return resp;
}

const KanbanAPI = {
  upsertAttachment,
  deleteAttachment,
  upsertBoard,
  getBoard,
  deleteBoard,
  getCard,
  upsertCard,
  deleteCard,
  getColumn,
  upsertColumn,
  deleteColumn,
  upsertComment,
  deleteComment,
  getAllBoardDataForCustomer,
  getBoards,
  upsertChecklist,
  deleteChecklist,
  upsertChecklistItem,
  deleteChecklistItem,
};

export default KanbanAPI;
