import { useEffect, type FC } from 'react';
import { useLocation, useRoutes } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@mui/material/styles';
import './i18n';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { inject } from '@vercel/analytics';
import { SpeedInsights } from '@vercel/speed-insights/react';
import GlobalStyles from './components/GlobalStyles';
import SplashScreen from './components/SplashScreen';
import useAuth from './hooks/useAuth';
import useSettings from './hooks/useSettings';
import routes from './routes';
import { createSIDTheme } from './theme';
import '@fontsource/inter';

inject();

const App: FC = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const auth = useAuth();
  useScrollReset();

  const theme = createSIDTheme({
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          dense
          maxSnack={3}
        >
          <GlobalStyles />
          {auth.isInitialized ? content : <SplashScreen />}
        </SnackbarProvider>
      </ThemeProvider>
      <SpeedInsights />
    </LocalizationProvider>
  );
};

const useScrollReset = (): null => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
};

export default App;
