import { useRef, useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from '@mui/material';
import { getCustomerDataForSelf } from 'src/slices/customerData';
import { getNotifications } from 'src/slices/notifications';
import CustomerDataApi from 'src/apis/CustomerDataApi';
import Cookie from 'src/lib/cookies';
import { RootState, useDispatch, useSelector } from 'src/store';
import { CustomerDataResponse } from 'src/apis/types/CustomerDataAPITypes';
import useAuth from '../../hooks/useAuth';
import CogIcon from '../../icons/Cog';
import UserIcon from '../../icons/User';

interface AccountPopoverProps {}

const customerDataSelfSelector = (state: RootState): CustomerDataResponse => {
  const { customerData } = state.customerData;
  return customerData.self;
};

const AccountPopover: FC<AccountPopoverProps> = (props) => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { logout } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let currentCustomerData = useSelector((state) => customerDataSelfSelector(state));
  let customerName = currentCustomerData == null ? '' : currentCustomerData.name;
  let customerAvatar = currentCustomerData == null ? '' : currentCustomerData.avatarURI;

  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState<boolean>(false);

  const checkForCustomerData = async (): Promise<void> => {
    if (currentCustomerData != null) {
      return;
    }
    dispatch(getNotifications());
    dispatch(getCustomerDataForSelf());
    const uuid = Cookie.getCookie(Cookie.Keys.UUID);
    const response = await CustomerDataApi.getCustomerDataByUUID(uuid);
    currentCustomerData = response;
    customerName = currentCustomerData.name;
    customerAvatar = currentCustomerData.avatarURI;
  };

  checkForCustomerData();

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
        variant: 'error',
      });
    }
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <Avatar
          src={customerAvatar}
          sx={{
            height: 32,
            width: 32,
          }}
        />
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography
            color="textPrimary"
            variant="subtitle2"
          >
            {customerName}
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ mt: 2 }}>
          <MenuItem
            component={RouterLink}
            to="/dashboard/account"
          >
            <ListItemIcon>
              <UserIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                >
                  Profile
                </Typography>
              }
            />
          </MenuItem>
        </Box>
        <Box sx={{ p: 2 }}>
          <Button
            color="primary"
            fullWidth
            onClick={handleLogout}
            variant="outlined"
          >
            Logout
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default AccountPopover;

/*
          <MenuItem
            component={RouterLink}
            to="/dashboard/account"
          >
            <ListItemIcon>
              <CogIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={(
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                >
                  Settings
                </Typography>
              )}
            />
          </MenuItem>
*/
